import React, { Component } from "react"
import Footer from "../../components/base/Footer"
import Header from "../../components/base/Header"
import SEO from "../../components/base/seo"

const termsandcondition = {
  title1: `Arus Oil - Syarat Perkhidmatan `,
  paragraph1: `Dokumen ini menetapkan Terma dan Syarat ("Syarat") di mana My Protech Sdn Bhd (1307580-A) ("My Protech"), memiliki pejabat berdaftar di IOI Rio Office Tower, Bandar Puteri Puchong, Selangor, Malaysia ("Kami", "Kami" atau "Kami") memberi Anda ("Anda", "Anda" atau "Pengguna") akses ke 'Arus Oil' ("Aplikasi"). Sila baca syarat-syarat ini dengan teliti. `,
  paragraph2: `Dengan mendaftar akaun melalui laman web atau menggunakan Aplikasi, anda menunjukkan penerimaan anda terhadap syarat perkhidmatan ini. Jangan gunakan Aplikasi ini atau kandungan atau perkhidmatannya jika anda tidak menerima syarat perkhidmatan ini.  `,
  paragraph3: `Syarat perkhidmatan ini boleh dipinda atau diperbaharui oleh My Protech dari semasa ke semasa tanpa pemberitahuan dan syarat perkhidmatan mungkin telah berubah sejak lawatan terakhir anda ke laman web ini. Ini  menjadi tanggungjawab anda untuk mengkaji syarat perkhidmatan jika terdapat sebarang perubahan. Penggunaan anda setelah apa-apa pindaan atau kemas kini syarat perkhidmatan ini akan menandakan persetujuan anda dan penerimaan syarat-syarat yang disemak semula. Sebarang ciri baru yang boleh ditambahkan ke laman web ini dari semasa ke semasa akan dikenakan syarat perkhidmatan ini, kecuali dinyatakan sebaliknya. Lawati halaman ini secara berkala untuk menyemak syarat perkhidmatan ini. `,
  paragraph4: `Untuk menggunakan perkhidmatan, anda mesti mendaftar dengan kami dan memberikan maklumat tertentu. Untuk maklumat mengenai bagaimana kami menggunakan maklumat peribadi anda, sila lihat dasar privasi Arus Oil, syarat-syaratnya digabungkan dengan merujuk kepada perjanjian ini. Jangan gunakan Aplikasi atau perkhidmatan kami jika anda tidak bersetuju dengan dasar privasi kami.`,
  paragraph5: `Aplikasi Arus Oil adalah platform di mana "Penjual" dapat menjual Minyak Masak Bekas mereka ("UCO") kepada "Pembeli". Harga jualan akan berubah mengikut harga minyak sawit global dan akan dimaklumkan kepada semua Penjual sebelum mendaftar keluar. Penjual mesti bersetuju dengan terma dan syarat sebelum penerimaan harga yang ditawarkan. Setelah tawaran dibuat, Pembeli akan diminta untuk menawarkan tawaran tersebut dan dibenarkan untuk mengatur pengambilan UCO secara logistik dan pembayaran akan dibuat terus ke dalam akaun bank Penjual. `,
  definition: `Definisi`,
  paragraph6:
    "“Aplikasi” merujuk pada bentuk kode objek dari Aplikasi Arus Oil, tersedia di pelayar internet dan boleh dimuat turun melalui Apple, Windows, Google Play dan Amazon. Aplikasi Arus Oil menawarkan ruang dalam talian yang membolehkan pengguna.",
  users: `("Penjual")`,
  paragraph7: `untuk menjual Minyak Masak Terpakai  `,
  usedcookingoil: `("UCO")`,
  paragraph8: `Pengguna yang menetapkan pengambilan, menerbitkan harga UCO, dan memudahkan pembayaran adalah  `,
  buyer: `"Pembeli"`,
  paragraph9: `dan Pengguna yang menjadualkan pengambilan UCO adalah`,
  sellers: `"Penjual"`,
  paragraph10: `Pembeli memantau semua temu janji yang dibuat dan memudahkan pengambilan dengan pihak dalaman atau pihak ketiga dan memudahkan pembayaran terus ke akaun bank Penjual melalui perkhidmatan pembayaran pihak ketiga. UCO dijual dan semua urus niaga yang dibuat dalam Aplikasi tidak dapat dikembalikan. Anda mesti mendaftarkan akaun untuk mengakses dan menggunakan ciri Aplikasi dan memastikan maklumat akaun anda tepat.`,
  title2: `Maklumat mengenai Aplikasi Arus Oil`,
  point1: `Aplikasi ini dimiliki dan diuruskan oleh My Protech. Semua maklumat yang diberikan melalui perkhidmatan diuruskan oleh My Protech. `,
  point2: `My Protech boleh dihubungi dengan menulis ke pejabat berdaftarnya di IOI Rio Office Tower, Bandar Puteri Puchong, Selangor, Malaysia. `,
  point3: `Aplikasi dihoskan dan dikendalikan di Google Firebase dan mematuhi semua terma dan syarat serta dasar privasi yang ditetapkan oleh Perkhidmatan Google.`,
  title3: `Penggunaan perkhidmatan `,
  paragraph11: `Anda mesti berumur sekurang-kurangnya 18 tahun untuk mendaftar ke Aplikasi sebagai Pengguna. Setelah mendaftar, anda mesti memberikan nama penuh, alamat e-mel, dan maklumat tambahan yang diperlukan. Segala maklumat peribadi yang diberikan oleh Pengguna kepada kami akan disimpan sesuai dengan Dasar Privasi Arus Oil, yang harus anda baca dengan teliti. Kami meminta anda memberikan nama sebenar anda semasa membuat akaun. Anda akan bertanggungjawab dan bertanggungjawab sepenuhnya atas segala kerugian, kerosakan, dan kos tambahan yang mungkin ditanggung oleh kami atau orang lain akibat penyerahan maklumat palsu, tidak betul atau tidak lengkap atau kegagalan anda untuk mengemas kini maklumat pendaftaran anda sekiranya diperlukan . Berikut adalah komitmen yang dibuat oleh pengguna berkaitan dengan pendaftaran dan menjaga keselamatan akaun mereka: `,
  sellerresp: `Tanggungjawab penjual`,
  point4: `Berikan maklumat yang tepat semasa pendaftaran dan teruskan maklumat tersebut;`,
  point5: `Pastikan maklumat log masuk pengguna anda dan akses ke Aplikasi terjamin. Kami tidak akan bertanggungjawab atas sebarang kerosakan yang disebabkan oleh kompromi akaun atau kata laluan anda, termasuk akses ke akaun atau penggunaan anda yang tidak dibenarkan. Sila hubungi kami dengan segera sekiranya anda mengesyaki atau menyedari penggunaan log masuk atau kata laluan e-mel anda yang tidak dibenarkan atau pelanggaran keselamatan yang lain`,
  point6: `Perlakukan Pengguna Aplikasi yang lain dengan siapa anda berinteraksi dengan hormat dan tidak melakukan sebarang tingkah laku yang mengancam, mengganggu atau membahayakan Pengguna atau pihak ketiga yang lain, atau melakukan apa sahaja yang melanggar garis panduan tingkah laku Pengguna umum yang disenaraikan di bawah. Kami berhak untuk menghentikan akses anda ke Aplikasi dan mengambil tindakan undang-undang yang sesuai jika anda melanggar syarat ini atau sekatan di bahagian di bawah ;`,
  point7: `Setuju untuk tidak memuat naik kandungan atau membuat Kumpulan Pengguna atau peristiwa yang berbahaya, kasar, merupakan ucapan kebencian, atau mengganggu, diskriminasi atau sebaliknya tidak menyenangkan dan berbahaya bagi Pengguna lain dan / atau orang ramai ;`,
  point8: `Sekiranya kita mematikan akaun Pengguna, mereka tidak akan membuat akaun lain tanpa kebenaran kita.`,
  point9: `Sekiranya anda menerima Syarat ini dan menggunakan Perkhidmatan atas nama syarikat, organisasi, pemerintah, atau entiti undang-undang lain, anda menyatakan dan menjamin bahawa anda diberi kuasa untuk melakukannya.`,
  point10: `Kecuali sebagaimana yang diizinkan secara jelas dalam Syarat ini, anda tidak boleh, dan tidak akan membenarkan orang lain untuk: `,
  point11: `mengubah, menterjemahkan, membuat salinan terbitan atau menyalin Aplikasi, secara keseluruhan atau sebahagian ;`,
  point12: `jurutera terbalik, menyusun semula, membongkar atau mengurangkan kod objek Aplikasi ke bentuk kod sumber ;`,
  point13: `mengedarkan, sub-lesen, memberikan, berkongsi, timeshare, menjual, menyewa, menpaja, memberikan kepentingan keselamatan, digunakan untuk tujuan biro perkhidmatan, atau memindahkan Aplikasi atau hak anda untuk menggunakan Aplikasi ;`,
  point14: `membuang atau mengubah sebarang hak cipta, tanda dagangan, atau notis hak milik lain milik kami atau pemberi lesen kami yang terdapat dalam Aplikasi; atau`,
  point15: `gunakan Aplikasi dengan cara apa pun yang tidak dibenarkan oleh Syarat ini.`,
  myprotechresp: `Tanggungjawab My Protech `,
  point16: `Kami berusaha untuk memberikan perkhidmatan terbaik yang kami dapat, tetapi anda memahami dan bersetuju bahawa ciri, fungsi, maklumat dan bahan yang disediakan di atau melalui laman web ini disediakan "sebagaimana adanya."khususnya, tetapi tanpa batasan, Arus Oil tidak mewakili, menjamin atau menawarkan syarat yang:`,
  point17: `Maklumat atau bahan di laman web ini betul, tepat, boleh dipercayai atau lengkap ;`,
  point18: `Fungsi yang terdapat di laman web ini tidak akan terganggu atau bebas ralat ;`,
  point19: `Kecacatan akan diperbetulkan; atau`,
  point20: `Laman web atau teknologi yang membuatnya tersedia bebas daripada virus atau komponen berbahaya yang lain. My Protech secara khusus menolak semua perwakilan, jaminan dan syarat, baik yang dinyatakan, tersirat, berkanun, dengan penggunaan perdagangan, cara berurusan atau sebaliknya termasuk tetapi tidak terhad kepada jaminan tersirat mengenai kebolehdagangan, bukan pelanggaran, tajuk, kualiti atau kecergasan yang memuaskan untuk tujuan tertentu. Segala maklumat atau bahan yang dimuat turun atau diperolehi melalui penggunaan laman web adalah mengikut budi bicara dan risiko anda sendiri dan anda akan bertanggungjawab sepenuhnya atas sebarang kerosakan pada sistem komputer anda, kehilangan data, atau kerugian lain yang timbul daripada memuat turun atau menggunakan bahan tersebut. My Protech tidak menjamin, menyokong, jaminan, memberikan apa-apa syarat atau perwakilan, atau memikul tanggungjawab untuk produk atau perkhidmatan yang diiklankan atau ditawarkan oleh pihak ketiga melalui laman web atau berkenaan dengan laman web yang boleh dihubungi dari pautan di laman web atau dipaparkan di sepanduk atau iklan lain di laman web, dan jumlah masa tidak boleh menjadi pihak dalam sebarang transaksi yang mungkin anda lakukan dengan pihak ketiga tersebut.`,
  point21: `Dalam keadaan apa pun, My Protech tidak akan bertanggungjawab atas sebarang kerosakan tidak langsung, sampingan, khas, akibat, teladan atau lain-lain, termasuk, tanpa batasan, sebarang kerosakan tidak langsung, sampingan, khas, akibat, teladan atau lain-lain yang disebabkan oleh: `,
  point22: `Penggunaan atau ketidakupayaan anda untuk menggunakan laman web ini atau maklumat atau bahan yang terdapat di Aplikasi ini ;`,
  point23: `Kos perolehan barang, data, maklumat atau perkhidmatan pengganti ;`,
  point24: `Kesalahan, kesilapan, atau ketidaktepatan dalam bahan di laman web ; `,
  point25: `Kecederaan peribadi, kematian atau kerosakan harta benda apa pun yang timbul daripada atau berkaitan dengan penggunaan Aplikasi anda ; `,
  point26: `Sebarang pepijat, virus, kuda trojan, atau file atau data lain yang mungkin berbahaya bagi peralatan atau data komputer atau komunikasi yang mungkin telah dihantar ke atau melalui laman web; atau`,
  point27: `Sebarang kesilapan atau peninggalan dalam sebarang bahan di laman web atau kehilangan atau kerosakan lain dari apa-apa jenis yang timbul dari atau berkaitan dengan penggunaan Aplikasi anda. Batasan ini akan berlaku walaupun My Protech telah diberitahu tentang kemungkinan kerosakan tersebut. Batasan di atas akan berlaku sepenuhnya yang dibenarkan oleh undang-undang.`,
  point28: `Kami berhak untuk membuat perubahan pada Aplikasi atau sebahagian daripadanya, dari semasa ke semasa termasuk tanpa batasan penghapusan, pengubahsuaian dan / atau variasi unsur, ciri dan fungsi Aplikasi apa pun.`,
  point29: `Tanpa menjejaskan hak dan penyelesaian kami yang lain, kami berhak untuk menangguhkan atau mematikan akses anda ke Aplikasi secara sementara atau kekal pada bila-bila masa tanpa pemberitahuan kepada anda sekiranya anda melanggar mana-mana peruntukan di sini. `,
  point30: `Sekiranya kita, mengikut budi bicara kami, pertimbangkan bahawa anda menggunakan Aplikasi secara haram dan / atau tidak sah, dan / atau penggunaan Aplikasi anda melanggar Syarat ini, kami berhak untuk mengambil tindakan yang kami anggap perlu, termasuk menamatkan tanpa pemberitahuan penggunaan Aplikasi anda dan, sekiranya berlaku penggunaan haram, menghasut prosiding undang-undang. `,
  title4: `Keahlian`,
  point31: `Keahlian Aplikasi anda adalah percuma dan akan berterusan dari kitaran bulan ke bulan sehingga penamatan.`,
  point32: `Kami mungkin menawarkan promosi yang ditawarkan oleh pihak ketiga bersama dengan penyediaan produk dan perkhidmatan mereka sendiri. Kami tidak bertanggungjawab untuk produk dan perkhidmatan jumlah rancangan keahlian, termasuk rancangan promosi khas atau keahlian yang disediakan oleh pihak ketiga tersebut.`,
  title5: `Penggunaan Aplikasi`,
  point33: `Di mana anda mengakses Aplikasi, anda mengakui dan bersetuju bahawa:`,
  point34: `My Protech sama sekali tidak dihubungkan, dihubungkan atau berafiliasi dengan Apple, Amazon, Google Play, Windows atau Android ; `,
  point35: `Akses dan penggunaan Aplikasi mungkin dibatasi oleh pembawa rangkaian anda dan akan terhad jika anda cuba mengakses Aplikasi dari luar kawasan pembawa rangkaian anda ; `,
  point36: `Anda mengakui bahawa anda tidak akan dapat mengakses dan menggunakan fungsi tertentu Aplikasi melainkan anda mempunyai akses internet melalui peranti mudah alih yang disambungkan dengan GPRS, 3G atau Wi-Fi. Semua caj lalu lintas atau caj akses yang dikenakan kerana penggunaan Aplikasi tertakluk pada syarat yang dipersetujui dengan penyedia rangkaian mudah alih anda ; `,
  point37: `Baik penyedia waktu tayang anda atau mana-mana kedai aplikasi atau kios atau pengendali kios (termasuk tetapi tidak terhad kepada Google Play App Store, AppStore Apple, Pasar Telefon Amazon dan Windows) akan memikul tanggungjawab atau tanggungjawab apa pun berkaitan dengan penjualan, pengedaran, fungsi, kebolehcapaian, sokongan atau prestasi atau tidak pelaksanaan Aplikasi; `,
  point38: `Your airtime provider and any app kiosk or kiosk operator (including but not limited to Google Play App Store, Apple AppStore, Amazon and Windows Phone Marketplace) are third-party beneficiaries in respect of this clause; `,
  point39: `My Protech bertanggungjawab sepenuhnya untuk memberikan sokongan dan penyelenggaraan berkenaan dengan Aplikasi; dan anda akan mematuhi apa-apa terma dan syarat pihak ketiga yang berlaku dalam penggunaan Aplikasi anda. Translation copied `,
  title6: `Terma dan Penamatan `,
  paragraph12: `Syarat-syarat ini akan terus berkuatkuasa dan berkuat kuasa semasa anda menggunakan Aplikasi. Kami boleh, pada bila-bila masa dan dengan alasan apa pun, menamatkan Syarat ini dengan anda dan menolak anda mengakses Aplikasi. Sekiranya penamatan Syarat ini dengan alasan apa pun, anda tidak boleh berusaha menggunakan Aplikasi ini.`,
  title7: `Harta Intelek `,
  paragraph13: `Selain daripada pautan ke laman web pihak ketiga, My Protech memiliki atau mempunyai lesen untuk menggunakan semua hak, hak dan kepentingan dalam dan ke Aplikasi, termasuk tanpa batasan semua hak cipta dan hak harta intelek lain di dalamnya. Syarat-syarat ini tidak boleh ditafsirkan untuk menyampaikan hak milik atau pemilikan Aplikasi atau kandungan yang terdapat di dalamnya kepada anda. Semua hak di dalam dan ke Aplikasi atau kandungan yang tidak diberikan secara jelas kepada anda dilindungi oleh kami. Anda dilarang membuang, mengubah suai, mengubah atau menggunakan tanda / logo berdaftar atau tidak berdaftar yang dimiliki oleh kami, dan melakukan apa sahaja yang mungkin dilihat mengambil keuntungan yang tidak adil dari reputasi dan muhibah kita atau boleh dianggap sebagai pelanggaran terhadap mana-mana hak harta intelek yang dimiliki dan / atau dilesenkan kepada kita, tanpa terlebih dahulu mendapat kebenaran bertulis dari My Protech. `,
  title8: `Jaminan`,
  paragraph14: `Sebarang kandungan, maklumat atau bahan yang diberikan kepada anda sebagai sebahagian daripada Aplikasi disediakan berdasarkan 'sebagaimana adanya' untuk kemudahan anda sahaja. Kandungan, maklumat dan bahan tersebut tidak merupakan nasihat atau cadangan dan oleh itu tidak boleh diandalkan semata-mata untuk membantu membuat atau menahan diri dari membuat keputusan, atau untuk membantu dalam memutuskan tindakan. Sejauh yang diizinkan oleh undang-undang, kami secara tegas mengecualikan semua pernyataan, jaminan, kewajiban dan liabiliti yang berkaitan dengan Aplikasi, dan setiap kandungan, maklumat atau bahan yang disediakan di dalamnya. `,
  title9: `Tanggungjawab`,
  point40: `Penggunaan Aplikasi disediakan secara percuma. `,
  point41: `Kami tidak akan bertanggungjawab untuk mana-mana: `,
  point42: `kerugian, kerosakan, kos dan perbelanjaan yang tidak dapat diramalkan ditanggung oleh anda dan tidak dapat diramalkan oleh anda atau kami semasa memasuki Syarat ini ; `,
  point43: `atau kehilangan keuntungan, kontrak atau perniagaan, kehilangan hasil yang dijangkakan atau jangkaan simpanan. `,
  title10: `Perlindungan dan Privasi Data `,
  point44: `Kami komited untuk melindungi (i) privasi anda; dan (ii) kerahsiaan maklumat yang diberikan oleh anda menggunakan Aplikasi. Untuk tujuan Akta Perlindungan Data 1988, pengawal data adalah My Protech Sdn Bhd (1307580-A), yang mempunyai pejabat berdaftar di IOI Rio Office Tower, Bandar Puteri Puchong, Selangor, Malaysia `,
  point45: `Sekiranya anda memberikan maklumat peribadi melalui Aplikasi, misalnya di mana anda mendaftar dan membuat akaun, kami akan menggunakan maklumat tersebut untuk tujuan berikut: `,
  point46: `untuk membantu memberi anda ciri dan perkhidmatan di Aplikasi ; `,
  point47: `untuk memberitahu anda mengenai perubahan pada Aplikasi ; `,
  point48: `dan untuk memberitahu anda mengenai kandungan dan ciri tambahan Aplikasi. `,
  point49: `Kami mungkin mendedahkan maklumat peribadi anda kepada pihak ketiga yang berafiliasi dengan acara tersebut (iaitu. memberikan maklumat kepada pihak ketiga untuk tujuan pengesahan). `,
  point50: `Semua maklumat yang anda berikan kepada kami disimpan di pelayan selamat kami. Dengan menghantar data peribadi anda, anda bersetuju dengan pemindahan dan / atau penyimpanan ini. Kami akan mengambil semua langkah yang diperlukan untuk memastikan data anda diperlakukan dengan selamat dan sesuai dengan prinsip perlindungan data. Malangnya, penghantaran maklumat melalui internet tidak sepenuhnya selamat. Walaupun kami akan melakukan yang terbaik untuk melindungi data peribadi anda, kami tidak dapat menjamin keselamatan data anda yang dihantar ke Aplikasi kami; sebarang penghantaran adalah atas risiko anda sendiri. Setelah kami menerima maklumat anda, kami akan menggunakan prosedur dan ciri keselamatan yang ketat untuk mengelakkan akses yang tidak dibenarkan. `,
  title11: `Am`,
  point51: `Anda boleh mencetak dan menyimpan salinan Syarat ini, yang membentuk keseluruhan perjanjian antara anda dan kami dan menggantikan komunikasi atau iklan lain berkenaan dengan Aplikasi. `,
  point52: `Syarat-syarat ini hanya boleh diubah dengan persetujuan bertulis kami terlebih dahulu. Kami boleh mengubah atau mengubah Syarat ini pada bila-bila masa, dengan serta-merta dan tanpa notis. Dengan terus menggunakan Aplikasi setelah perubahan tersebut, anda akan dianggap telah menerima pindaan terhadap Syarat ini. `,
  point53: `Syarat-syarat ini dan prestasinya akan diatur oleh dan ditafsirkan sesuai dengan undang-undang Malaysia dan pihak-pihak dengan ini menyerahkan kepada bidang kuasa pengadilan Malaysia yang tidak eksklusif. `,
  point54: `Anda harus mematuhi semua undang-undang dan peraturan asing dan tempatan yang berlaku untuk penggunaan Aplikasi anda di negara mana pun anda berada secara fizikal, termasuk tanpa batasan, undang-undang pengguna, undang-undang dan peraturan kawalan eksport. `,
  point55: `Anda bersetuju bahawa kerana sifat Aplikasi yang unik, dan hak milik kami di dalamnya, pelanggaran Syarat ini yang ditunjukkan oleh anda akan membahayakan kami dan kerosakan wang akan menjadi pampasan yang tidak mencukupi. Oleh itu, anda bersetuju bahawa kami berhak mendapat pelepasan injunksi awal dan kekal, seperti yang ditentukan oleh mana-mana mahkamah yang mempunyai bidang kuasa yang kompeten untuk menguatkuasakan peruntukan Syarat ini. `,
  point56: `Sekiranya mana-mana peruntukan Syarat ini dinyatakan batal, tidak sah, atau tidak dapat dilaksanakan, selebihnya dari Syarat ini akan berlaku dan dapat dilaksanakan sejauh yang diizinkan oleh undang-undang yang berlaku. Dalam hal seperti itu, para pihak setuju untuk menggunakan upaya terbaik mereka untuk menggantikan ketentuan yang tidak sah atau tidak dapat dilaksanakan dengan ketentuan yang, sejauh yang diizinkan oleh undang-undang yang berlaku, mencapai tujuan yang dimaksudkan di bawah peruntukan yang tidak sah atau tidak dapat dilaksanakan.`,
  point57: `Sebarang kegagalan oleh mana-mana pihak dalam Syarat ini untuk menguatkuasakan pada bila-bila masa apa-apa terma atau syarat di bawah Syarat ini tidak akan dianggap sebagai pengabaian hak pihak tersebut selepas itu untuk menguatkuasakan setiap terma dan syarat Syarat ini. `,
  point58: `Kedua-dua pihak tidak akan bertanggungjawab atas kelewatan yang disebabkan oleh keadaan di luar kawalan yang wajar dari pihak tersebut, dengan syarat pihak yang tidak berprestasi menggunakan usaha yang wajar untuk mengelakkan atau menghapus sebab-sebab yang tidak dilaksanakan dan meneruskan prestasi di bawah ini dengan penghantaran yang wajar setiap kali sebab tersebut dikeluarkan .`,
  point59: `Tiada apa-apa dalam Syarat ini yang akan memberikan, secara langsung atau tidak langsung, mana-mana pihak ketiga apa-apa faedah yang boleh dilaksanakan atau hak tindakan terhadap kami dan pihak ketiga tersebut tidak berhak untuk menguatkuasakan mana-mana terma ini terhadap kami. `,
  point60: `Sekiranya anda merasakan bahawa sebarang bahan yang muncul di Aplikasi itu menyinggung perasaan, tidak menyenangkan atau berpotensi memfitnah, sila hubungi kami untuk memberikan maklumat lengkap mengenai sifat aduan anda dan bahan-bahan yang berkaitan dengan aduan tersebut. `,
}

export default class Tutorial extends Component {
  render() {
    return (
      <div className="min-h-screen bg-gray-100">
        <SEO
          title="Arus Oil - Terma dan Syarat"
          description="Halaman ini menetapkan Terma dan Syarat (“Syarat”) di mana My Protech Sdn Bhd (1307580-A) (“My Protech”), memiliki pejabat berdaftar di IOI Rio Office Tower, Bandar Puteri Puchong, Selangor, Malaysia (“Kami”, “Kami” atau “Kami”) menyediakan Anda (“Anda”, “Anda” atau “Pengguna”) akses ke 'Arus Oil' (“Aplikasi”) "
        />
        <Header page="Terms and Conditions" />
        <header>
          <div className="pt-14 max-w-7xl flex mx-auto px-4 sm:px-6 lg:px-8">
            <h1 className="text-4xl font-extrabold tracking-tight text-warm-gray-900">
              {termsandcondition.title1}
            </h1>
          </div>
        </header>
        <main>
          <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
            <div className="block px-4 py-8 sm:px-0">
              <div className="flex flex-col mt-2">
                <div className="align-middle min-w-full overflow-x-auto shadow overflow-hidden sm:rounded-lg">
                  <div className="bg-white px-4 py-3 flex flex-col items-center justify-between border-t border-gray-200 sm:px-6">
                    <div className="relative max-w-7xl text-justify pt-6 pb-16 sm:px-6">
                      <p className="mt-2 text-sm">
                        {termsandcondition.paragraph1}
                        <br />
                        <br />
                        {termsandcondition.paragraph2}
                        <br />
                        <br />
                        {termsandcondition.paragraph3}
                        <br />
                        <br />
                        {termsandcondition.paragraph4}
                        <br />
                        <br />
                        {termsandcondition.paragraph5}
                        <br />
                        <br />
                        <br />
                        <span className="font-bold text-xl">
                          {termsandcondition.definition}
                        </span>
                        <br />
                        <br />
                        {termsandcondition.paragraph6}
                        <span className="font-bold">
                          {termsandcondition.users}
                        </span>{" "}
                        {termsandcondition.paragraph7}
                        <span className="font-bold">
                          {termsandcondition.usedcookingoil}
                        </span>
                        {". "}
                        {termsandcondition.paragraph8}
                        <span className="font-bold">
                          {termsandcondition.buyer}
                        </span>{" "}
                        {termsandcondition.paragraph9}
                        <span className="font-bold">
                          {termsandcondition.sellers}
                        </span>
                        {". "}
                        {termsandcondition.paragraph10}
                      </p>
                      <h2 className="mt-12 text-xl font-bold">
                        {termsandcondition.title2}
                      </h2>
                      <ul className="ml-4 mt-2 list-disc text-sm">
                        <li>{termsandcondition.point1}</li>
                        <li>{termsandcondition.point2}</li>
                        <li>{termsandcondition.point3}</li>
                      </ul>

                      <h2 className="mt-12 text-xl font-bold">
                        {termsandcondition.title3}
                      </h2>
                      <p className="mt-2 text-sm">
                        {termsandcondition.paragraph11}
                      </p>
                      <h2 className="mt-12 text-xl font-bold">
                        {termsandcondition.sellerresp}
                      </h2>
                      <ul className="ml-4 mt-2 list-disc text-sm">
                        <li>{termsandcondition.point4}</li>
                        <li>{termsandcondition.point5}</li>
                        <li>{termsandcondition.point6}</li>
                        <li>{termsandcondition.point7}</li>
                        <li>{termsandcondition.point8}</li>
                        <li>{termsandcondition.point9}</li>
                        <li>
                          {termsandcondition.point10}
                          <ul className="ml-4 list-decimal">
                            <li>{termsandcondition.point11}</li>
                            <li>{termsandcondition.point12}</li>
                            <li>{termsandcondition.point13}</li>
                            <li>{termsandcondition.point14}</li>
                            <li>{termsandcondition.point15}</li>
                          </ul>
                        </li>
                      </ul>
                      <h2 className="mt-12 text-xl font-bold">
                        {termsandcondition.myprotechresp}
                      </h2>
                      <ul className="ml-4 mt-2 list-disc text-sm">
                        <li>{termsandcondition.point16}</li>
                        <ul className="ml-4 list-decimal">
                          <li>{termsandcondition.point17}</li>
                          <li>{termsandcondition.point18}</li>
                          <li>{termsandcondition.point19}</li>
                          <li>{termsandcondition.point20}</li>
                        </ul>
                        <li>{termsandcondition.point21}</li>
                        <ul className="ml-4 list-decimal">
                          <li>{termsandcondition.point22}</li>
                          <li>{termsandcondition.point23}</li>
                          <li>{termsandcondition.point24}</li>
                          <li>{termsandcondition.point25}</li>
                          <li>{termsandcondition.point26}</li>
                          <li>{termsandcondition.point27}</li>
                        </ul>
                        <li>{termsandcondition.point28}</li>
                        <li>{termsandcondition.point29}</li>
                        <li>{termsandcondition.point30}</li>
                      </ul>
                      <h2 className="mt-12 text-xl font-bold">
                        {termsandcondition.title4}
                      </h2>
                      <ul className="ml-4 list-disc text-sm">
                        <li>{termsandcondition.point31}</li>
                        <li>{termsandcondition.point32}</li>
                      </ul>
                      <h2 className="mt-12 text-xl font-bold">
                        {termsandcondition.title5}
                      </h2>
                      <p className="mt-2 text-sm">
                        {termsandcondition.point33}
                      </p>
                      <ul className="ml-4 mt-2 list-disc text-sm">
                        <li>{termsandcondition.point34}</li>
                        <li>{termsandcondition.point35}</li>
                        <li>{termsandcondition.point36}</li>
                        <li>{termsandcondition.point37}</li>
                        <ul className="ml-4 list-decimal">
                          <li>{termsandcondition.point38}</li>
                          <li>{termsandcondition.point39}</li>
                        </ul>
                      </ul>
                      <h2 className="mt-12 text-xl font-bold">
                        {termsandcondition.title6}
                      </h2>
                      <p className="mt-2 text-sm">
                        {termsandcondition.paragraph12}
                      </p>
                      <h2 className="mt-12 text-xl font-bold">
                        {termsandcondition.title7}
                      </h2>
                      <p className="mt-2 text-sm">
                        {termsandcondition.paragraph13}
                      </p>
                      <h2 className="mt-12 text-xl font-bold">
                        {termsandcondition.title8}
                      </h2>
                      <p className="mt-2 text-sm">
                        {termsandcondition.paragraph14}
                      </p>
                      <h2 className="mt-12 text-xl font-bold">
                        {termsandcondition.title9}
                      </h2>
                      <ul className="ml-4 mt-2 list-disc text-sm">
                        <li>{termsandcondition.point40}</li>
                        <li>{termsandcondition.point41}</li>
                        <ul className="ml-4 list-decimal">
                          <li>{termsandcondition.point42}</li>
                          <li>{termsandcondition.point43}</li>
                        </ul>
                      </ul>
                      <h2 className="mt-12 text-xl font-bold">
                        {termsandcondition.title10}
                      </h2>
                      <ul className="ml-4 mt-2 list-disc text-sm">
                        <li>{termsandcondition.point44}</li>
                        <li>{termsandcondition.point45}</li>
                        <ul className="ml-4 list-decimal">
                          <li>{termsandcondition.point46}</li>
                          <li>{termsandcondition.point47}</li>
                          <li>{termsandcondition.point48}</li>
                        </ul>
                        <li>{termsandcondition.point49}</li>
                        <li>{termsandcondition.point50}</li>
                      </ul>
                      <h2 className="mt-12 text-xl font-bold">
                        {termsandcondition.title11}
                      </h2>
                      <ul className="ml-4 mt-2 list-disc text-sm">
                        <li>{termsandcondition.point51}</li>
                        <li>{termsandcondition.point52}</li>
                        <li>{termsandcondition.point53}</li>
                        <li>{termsandcondition.point54}</li>
                        <li>{termsandcondition.point55}</li>
                        <li>{termsandcondition.point56}</li>
                        <li>{termsandcondition.point57}</li>
                        <li>{termsandcondition.point58}</li>
                        <li>{termsandcondition.point59}</li>
                        <li>{termsandcondition.point60}</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
        <Footer />
      </div>
    )
  }
}
